/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../src/images/', true)
const imagePath = (name) => images(name, true)

require("@rails/ujs");
import Rails from "@rails/ujs"
window.Rails = Rails
Rails.start();
// require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels")

require("../src/scripts/main");

// MAPS
require("leaflet");
import * as L from 'leaflet';
require("leaflet-geosearch");
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
require("leaflet.markercluster");
import { MarkerClusterGroup } from 'leaflet.markercluster';
import { GestureHandling } from "leaflet-gesture-handling";

require("orgchart.js");
import OrgChart from "orgchart.js";
window.OrgChart = OrgChart;

require("flatpickr");
require("flatpickr/dist/l10n/lv.js");
require("flatpickr/dist/l10n/lt.js");
require("flatpickr/dist/l10n/de.js");
require("flatpickr/dist/l10n/ru.js");
require("flatpickr/dist/l10n/et.js");
import flatpickr from "flatpickr"
window.flatpickr = flatpickr;

require("lightbox2");
require.context("lightbox2/dist/images", true);
require("lightbox2/dist/css/lightbox.css");
import lightbox from 'lightbox2';
window.lightbox = lightbox;

require('select2/dist/js/select2.full');
require('select2/dist/css/select2.css');
require('select2/dist/js/i18n/en');
require('select2/dist/js/i18n/lv');
require('select2/dist/js/i18n/ru');

// web components
import { initWebComponents } from '../../components/index';

// MDC imports
import { MDCTopAppBar } from '@material/top-app-bar';

import { MDCRipple } from '@material/ripple';
import { MDCTextField } from '@material/textfield';
import { MDCTextFieldHelperText } from '@material/textfield/helper-text';
import { MDCFormField } from '@material/form-field';
import { MDCRadio } from '@material/radio';
import { MDCCheckbox } from '@material/checkbox';
import { MDCSwitch } from '@material/switch';
import { MDCMenu } from '@material/menu';
import { MDCDataTable } from '@material/data-table';
import { MDCChipSet } from '@material/chips';
import { MDCLinearProgress } from '@material/linear-progress';
import { MDCSlider } from '@material/slider';
import snackbar from '../src/scripts/common/snackbar';
window.snackbar = snackbar;

// Tabs
import { MDCTabBar } from '@material/tab-bar';
import { MDCTabScroller } from '@material/tab-scroller';
import { MDCTab } from '@material/tab';

// For Menu Anchor Corner setup;
import { Corner as cbMenuCorner } from '@material/menu-surface/constants';

// Notifications/Flash - MDC Banner and Snackbar
// import {MDCBanner} from '@material/banner';
import { MDCSnackbar } from '@material/snackbar';

// Charts
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { createPopper } from '@popperjs/core/lib/popper-lite.js';

window.$ = $
/*
    MDC
*/

// Define init method names for ajax usage
window.mdcInit = mdcInit;
window.mdcInitDataTable = mdcInitDataTable;
window.initTables = initTables;
window.mdcInitSnackbar = mdcInitSnackbar;
window.createPopper = createPopper;

$(document).on("page:change", function () {
  var scope = document;
  lightboxOpts();

  // NOTE: Double init page:change | app.initialize
  mdcInit(scope);

  // LEAFLET INITS
  initHomeMap();
  // initDisplayMap();
  // initFormMap();
  initToolbarMap();

  // CHARTS
  initCharts(scope);

  // NOTE: Init table for checkboxes, sortable and loading indicator
  mdcInitDataTable();
  initTables(scope);

  // View switcher (todo / site visits)
  initViewSwitcher(scope);

  // CHECKLISTS
  initChecklists(scope);

  var tables = document.querySelectorAll('.mdc-data-table');
  if (tables.length) {
    [].map.call(tables, function (el) {
      el.mdcDataTable.initialPageLoad();
    });
  }

  // BODY NO SCROLL STATE
  initBody();

  // WebComponents
  initWebComponents();
});


// Workaround to float labels on changed flatpickr inputs
$(document).on('blur', '.mdc-text-field input.mdc-text-field__input.flatpickr-input', function () {
  var $textfield = $(this).closest('.mdc-text-field'),
    hasValue = $(this).val().length > 0;

  // setTimout because of flatpickr onBlur actions
  setTimeout(function () {
    $textfield[0].mdcTextField.blur(hasValue);
  }, 10);
});

$(document).on('MDCTabBar:activated', '.mdc-tab-bar', function (e) {
  // Dataset target is #cbTabContent
  let $tabContent = $(this.dataset.target);

  if (!$tabContent || $tabContent.length == 0) return;

  let index = e.detail.index,
    tab = $(this).find('.mdc-tab')[index];

  let sections = [];
  if ($(tab).data('sections')) {
    $.each($(tab).data('sections'), function (target, data) {
      let $section = skeletonLoadingPlaceholder(target, data['url'], data['title']);
      sections.push($section);
    });
  } else {
    let $section = skeletonLoadingPlaceholder(tab.dataset.hash, tab.dataset.url,
      tab.getAttribute('title'));
    sections.push($section);
  }

  $tabContent.html(sections);
  loadTabSections(sections, $tabContent);
});

$(document).on('change', '.cb-check-all', function () {
  var $container = $(this).closest('.cb-check-container'),
    val = $(this).is(':checked');

  $container.find('.cb-check').prop('checked', val).change();
})


/* Table */
// Rotated table headers
function initTables(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  [].map.call(scope.querySelectorAll('.cb-table-th-rotated'), function (el) {
    let $table = $(el),
        $headers = $table.find('.cb-cell-rotate span');

    let widths = $.map($headers, function (e) { return $(e).outerWidth() });
    if (!widths) return;

    let thSpanWidth = Math.max(...widths),
        thHeight = Math.round(thSpanWidth / 1.42);

    if (thHeight > 224) { thHeight = 224 }
    $headers.closest('.cb-cell-rotate').height(thHeight);
    $headers.width(thSpanWidth);
  });
}

function skeletonLoadingPlaceholder(id, url, title) {
  let $div = $('<div>'),
    $skeleton = $div.clone().addClass('cb-tab-section'),
    urlPart = url.split('/').filter(e => e).pop(),
    $skeletonPlaceholder;

  $skeleton.attr('id', id).attr('data-url', url);

  let skeletonHtml = `
    <div class="cb-toolbar">
      <div class="cb-toolbar-header mdc-card mdc-card--outlined">
        <div class="cb-toolbar-header__icon-wrapper">
          <span class="cb-toolbar-header__icon material-icons" aria-hidden="true">
            radio_button_unchecked
          </span>
        </div>
        <div class="cb-toolbar-header__text">
          <div class="cb-toolbar-header__primary-text">
            <span>`+ title + `</span>
          </div>
        </div>
      </div>

      <div role="progressbar" class="mdc-linear-progress mdc-linear-progress--indeterminate"
          aria-valuemin="0" aria-valuemax="1" aria-valuenow="0">
        <div class="mdc-linear-progress__buffer">
          <div class="mdc-linear-progress__buffer-bar"></div>
          <div class="mdc-linear-progress__buffer-dots"></div>
        </div>
        <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
          <span class="mdc-linear-progress__bar-inner"></span>
        </div>
        <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
          <span class="mdc-linear-progress__bar-inner"></span>
        </div>
      </div>
    </div>

    <div class="cb-skeleton-loading__content mdc-card mdc-card--outlined">
    </div>
  `

  skeletonHtml = $div.clone().addClass('cb-skeleton-loading').html($(skeletonHtml));
  let $skeletonContent = skeletonHtml.find('.cb-skeleton-loading__content');
  let skeletonItem = $div.clone().addClass('cb-skeleton-loading__item')

  if (urlPart == 'compact_show') {
    for (var i = 0; i < 5; i++) {
      $skeletonContent.append(skeletonItem.clone());
    }

  } else if (urlPart == 'list') {
    for (var i = 0; i < 3; i++) {
      $skeletonContent.append($('<div class="cb-skeleton-loading__item-row">').html(skeletonItem.clone()));
    }

  } else {
    for (var i = 0; i < 6; i++) {
      $skeletonContent.append(skeletonItem.clone());
    }
  }

  $skeleton.html(skeletonHtml);
  mdcInitLinearProgress($skeleton);
  return $skeleton;
}

// List table search
$(document).on('keyup', '.cb-tab-section .cb-data-table__search-bar', function (e) {
  const minChars = 3,
        inputDelay = 1000;

  let input = this,
      q = input.value;

  if (q && q.length < minChars) return false;

  let parentSection = $(this).closest('.cb-tab-section'),
      url = parentSection.data('url');

  if (input.dataset.prevVal == q) {
    return false;
  } else {
    input.dataset.prevVal = q;
  }

  let table = $(parentSection).find('.mdc-data-table')[0];
  window.clearTimeout(input.tableTimeout);
  window.clearTimeout(input.timeout);

  input.tableTimeout = setTimeout(function () {
    table.mdcDataTable.showProgress();
  }, inputDelay / 2)


  input.timeout = setTimeout(function () {
    if (url) {
      // new URL requires a full url, adding localhost as a dummy hostname
      if (!url.startsWith('http')) { url = 'http://localhost:3000' + url };
      url = new URL(url);
      url.searchParams.set('q', q);
      url = url.pathname + url.search;
      // loadTabSection(parentSection, parentSection.closest('#cbTabContent'), url);
      loadTabSectionTable(parentSection, url, table, '.mdc-data-table');
    }
  }, inputDelay)
});


/* Paginator */
// Remote table pagination, scopes, filters and sorting in lists
$(document).on('click', '.cb-tab-section .mdc-data-table__pagination a, .cb-tab-section .cb-data-table__filter-wrapper a, .cb-tab-section a.cb-reload-table, .cb-tab-section a.cb-table-sort', function(e){
  e.preventDefault();

  let parentSection = $(this).closest('.cb-tab-section'),
    url = this.href;

  loadTabSection(parentSection, parentSection.closest('#cbTabContent'), url);
});

// Remote table pagination and scopes in modals
// reload just table
$(document).on('click', '.modal-body .mdc-data-table__pagination a, .modal-body .cb-data-table__filter-wrapper a', function (e) {
  e.preventDefault();

  let parentSection = this.closest('.cb-data-table'),
    url = this.href,
    $table = $(parentSection).find('.mdc-data-table'),
    parentSelector = $table.find('table').attr('data-parent'),
    contentSection = this.closest('.modal-content');

  // Re-init mdc data table
  if (!$table[0].mdcDataTable && window.mdcInitDataTable) {
    window.mdcInitDataTable(parentSection);
  }

  loadTabSectionTable(parentSection, url, contentSection, parentSelector);
});

$(document).on('click', 'header .cb-mdc-top-app-bar__menu', function (e) {
  document.body.bodyScrollStateChange();

  var $snackbar = $('.mdc-snackbar');
  if ($snackbar.length && $snackbar[0].mdcSnackbar) {
    $snackbar[0].mdcSnackbar.close('dismiss');
  }
});

function initBody() {
  document.body.bodyScrollStateChange = function (state) {
    let body = document.body,
      fab = body.querySelector('.cb-fab');
    open;

    if (typeof state !== 'undefined') {
      open = state;
    } else {
      if ($('header .cb-contextual-bar--active[data-content="search"]').length) open = true;
    }

    if (open) {
      // NOTE: When body is fixed, it loses scrollbar (width changes)
      // fab also moves to right. Set fab's (current) left position
      if (fab) {
        fab.style.left = parseInt($(fab).offset().left) + 'px';
        fab.style.right = 'auto';
      }

      // Make body fixed
      body.style.top = -parseInt(window.scrollY) + 'px';
      body.classList.add('body--fixed');

    } else {
      const scrollTo = body.style.top;

      body.classList.remove('body--fixed');
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollTo || '0') * -1);

      if (fab) {
        fab.style.left = '';
        fab.style.right = '';
      }
    }
  }
}
// << end of bodyScrollState


function mdcInit(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  mdcInitRipple(scope);
  mdcInitTextField();
  mdcInitRadioAndCheckbox();
  mdcInitSwitch();
  mdcInitMenu();
  mdcInitTabBar(scope);
  mdcInitLinearProgress(scope);
  mdcInitChipSet();
  mdcInitSlider(scope);
  // mdcInitBanner();

  initFlashSnackbar(scope);
  mdcInitSnackbar(scope);

  // TODO: Move to more suitable place
  initListMap();
}




/*
    MDC init components
*/

/* Tab, Tab bar */
function mdcInitTabBar(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  const url = new URL(location.href),
    tabName = url.searchParams.get('tab');

  [].map.call(scope.querySelectorAll('.mdc-tab-bar'), function (el) {
    if (el.mdcTabBar) return;
    el.mdcTabBar = new MDCTabBar(el);
    el.mdcTabScroller = new MDCTabScroller(el.querySelector('.mdc-tab-scroller'));
  });

  [].map.call(scope.querySelectorAll('.mdc-tab'), function (el) {
    if (el.mdcTab) return;
    var tab = new MDCTab(el);
    tab.focusOnActivate = false;
    el.mdcTab = tab;
  });
}


/* Ripple */
function mdcInitRipple(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  [].map.call(scope.querySelectorAll('.mdc-button, .mdc-fab'), function (el) {
    new MDCRipple(el);
  });

  [].map.call(scope.querySelectorAll('.mdc-icon-button'), function (el) {
    var iconBtn = new MDCRipple(el);
    iconBtn.unbounded = true;
  });
}


/* Text fields */
function mdcInitTextField() {
  [].map.call(document.querySelectorAll('.mdc-text-field'), function (el) {
    let mdcTextField = new MDCTextField(el);

    mdcTextField.setFloat = function (shouldFloat) {
      this.foundation.adapter.floatLabel(shouldFloat);
      this.foundation.notchOutline(shouldFloat);
    }

    mdcTextField.blur = function (toggle) {
      this.setFloat(toggle);
      this.foundation.deactivateFocus();
    }
    if (el.classList.contains('mdc-text-field--label-floating')) {
      // Float labels after all elements loaded
      // (label name was truncated)
      setTimeout(function () { mdcTextField.setFloat(true); }, 50);
    };
    $(el).on("change", function() {
      mdcTextField.blur(true);
    })
    el.mdcTextField = mdcTextField;
  });

  [].map.call(document.querySelectorAll('.mdc-text-field-helper-text'), function (el) {
    return new MDCTextFieldHelperText(el);
  });
}


/* Radio and Checkboxes */
function mdcInitRadioAndCheckbox() {
  // Function to update the active class
  function updateActiveClass(container) {
    if (!container) return;

    container.querySelectorAll('.mdc-form-field').forEach(function(field) {
      const input = field.querySelector('.mdc-radio__native-control');
      field.classList.toggle('cb-radio-form-field--selected', input.checked);
    });
  }

  [].map.call(document.querySelectorAll('.mdc-form-field .mdc-radio'), function (el) {
    let radio     = new MDCRadio(el),
        formField = new MDCFormField(el.closest('.mdc-form-field'));

    // Add change event listener to the radio input
    radio.listen('change', function(event) {
      const container = el.closest('.cb-radio-form-field-wrapper');
      updateActiveClass(container);
    });

    // Function to wrap the original setDisabled method and update parent wrapper
    radio.setDisabled = function(isDisabled) {
      // Call the original setDisabled method
      radio.foundation.setDisabled(isDisabled);

      // Get the wrapper element
      const wrapper = radio.root.closest('.cb-radio-form-field');

      // Update the wrapper class based on the disabled state
      wrapper.classList.toggle('cb-radio-form-field--disabled', isDisabled);
    };

    // Initial update on page load
    let formWrapper = el.closest('.cb-radio-form-field-wrapper');
    updateActiveClass(formWrapper);

    formField.input = radio;
    el.mdcFormField = formField;
  });

  [].map.call(document.querySelectorAll('.mdc-form-field .mdc-checkbox'), function (el) {
    var checkbox = new MDCCheckbox(el),
        formField = new MDCFormField(el.closest('.mdc-form-field'));

    formField.input = checkbox;
    el.mdcFormField = formField;
  });
}


/* Switch */
function mdcInitSwitch() {
  [].map.call(document.querySelectorAll('.mdc-switch'), function (el) {
    let checked = el.classList.contains('mdc-switch--checked'),
        mdcSwitch = new MDCSwitch(el);
    el.mdcSwitch = mdcSwitch;
    mdcSwitch.foundation.setChecked(checked);
    $(el).on("change", function () { this.mdcSwitch.foundation.setChecked($(this).find("input[type=checkbox]").prop("checked")); })
  });
}


/* Menu */
function mdcInitMenu() {
  [].map.call(document.querySelectorAll('.mdc-menu'), function (el) {
    let menu = new MDCMenu(el),
      toggleBtn = el.parentNode.querySelector('.cb-mdc-menu-toggle');

    menu.setAnchorCorner(cbMenuCorner.BOTTOM_LEFT);
    menu.open = false;
    if (el.dataset.position && el.dataset.position != 'fixed') {
      menu.setFixedPosition(false);
      menu.setIsHoisted(false);
    } else {
      menu.setFixedPosition(true);
      menu.setIsHoisted(true);
    }

    menu.listen('MDCMenuSurface:opened', () => {
      toggleBtn.classList.add('cb-mdc-menu--open');
      tableMenuButton(toggleBtn, true);
    });

    menu.listen('MDCMenuSurface:closed', () => {
      toggleBtn.classList.remove('cb-mdc-menu--open');
      tableMenuButton(toggleBtn, false);
    });

    toggleBtn.addEventListener('click', function (e) {
      if (!toggleBtn.classList.contains('cb-mdc-menu--open')) menu.open = !menu.open
    });
    el.mdcmenu = menu;
  });
}

function tableMenuButton(btn, state) {
  if ($(btn).hasClass('cb-table-cell-menu-toggle')) {
    let zIndex = state ? '2' : ''

    $(btn).closest('td.mdc-data-table__cell').css({ 'z-index': zIndex });
  }
}


/* Data table */
function mdcInitDataTable(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  [].map.call(scope.querySelectorAll('.mdc-data-table'), function (el) {
    let dataTable = new MDCDataTable(el);

    dataTable.initialPageLoad = function () {
      let dataTable = this,
        table = this.root,
        url = table.querySelector('table').dataset.url;

      if (url) {
        let parent = table.closest('.cb-data-table').parentNode;
        dataTable.showProgress();

        $.ajax({
          url: url,
          dataType: 'JSON',
          error: function () { dataTable.hideProgress() },
          beforeSend: function () { dataTable.showProgress() },
          success: function (data) {
            const html = data.html;
            dataTable.hideProgress();

            // Replace, overwrite and re-initialize table
            parent.innerHTML = html;
            dataTable.root = parent.querySelector('.mdc-data-table');
            dataTable.initialSyncWithDOM();
            if (typeof init_preloads === "function") { init_preloads(parent); }

            // Can't get reference to mdcDataTable from selected el (reloaded html)
            // set element as new root element
            el = dataTable.root;
            el.mdcDataTable = dataTable;
          }
        })
      }
    }

    el.mdcDataTable = dataTable;
  });
}


/* Linear progress */
function mdcInitLinearProgress(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  scope.querySelectorAll('.mdc-linear-progress').forEach((item, i) => {
    const indicator = new MDCLinearProgress(item);
    let progress = item.getAttribute('aria-valuenow');
    if (progress) { indicator.progress = progress; }
    item.mdcLinearProgress = indicator;
  })
}


/* Slider input */
function mdcInitSlider(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  [].map.call(scope.querySelectorAll('.mdc-slider'), function (el) {
    let mdcSlider = new MDCSlider(el);
    mdcSlider.reloadPosition = function () {
      this.foundation.resizeListener();
    }

    let valueIndicator = el.closest('.slider').querySelector('.slider__label-value');
    valueIndicator = valueIndicator || el.querySelector('.mdc-slider__value-indicator-text');
    valueIndicator.setValue = function(value) {
      let classPrefix = this.classList[0],
          className = classPrefix + '--highlight',
          initialValue = this.textContent;

      this.textContent = value;

      if (initialValue !== '') {
        // "Force reflow" trick
        this.classList.add(className);
        void this.offsetWidth;
        this.classList.remove(className);
      }
    }
    mdcSlider.valueIndicator = valueIndicator;

    // Get the value indicator text element
    const inputElement = el.querySelector('input'),
          valueMap = {};

    // Parse the mapping from the input's value
    if (inputElement && inputElement.dataset.values) {
      inputElement.dataset.values.split(';').forEach(pair => {
        const [key, value] = pair.split(':');
        valueMap[parseFloat(key)] = value.trim();
      });
    }

    mdcSlider.updateSlider = () => {
      const value = mdcSlider.getValue();
      const mappedText = valueMap[value] || value;
      if (mdcSlider.valueIndicator) mdcSlider.valueIndicator.setValue(mappedText);
      return mappedText;
    };

    mdcSlider.setValueToAriaValueTextFn(mdcSlider.updateSlider);
    mdcSlider.listen('MDCSlider:input', mdcSlider.updateSlider);
    mdcSlider.updateSlider();

    el.mdcSlider = mdcSlider;
  });
}

// Add event listener for modal content loaded
$(document).on('shown.bs.modal', function (e) {
  let modal = e.target;
  [].map.call(modal.querySelectorAll('.mdc-slider'), function (el) {
    el.mdcSlider.reloadPosition();
  });
});

/* Chip set */
function mdcInitChipSet() {
  [].map.call(document.querySelectorAll('.mdc-chip-set'), function (el) {
    return new MDCChipSet(el)
  });
}


/* Banner */
function mdcInitBanner() {
  [].map.call(document.querySelectorAll('.mdc-banner'), function (el) {
    el.mdcBanner = new MDCBanner(el);
  });
}




/* Snackbar */
// Flash snackbar
function initFlashSnackbar(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  const snackbar = require("../src/scripts/common/snackbar");
  let flashSnackbar = scope.querySelector('.cb-snackbar');
  if (flashSnackbar) snackbar.show(flashSnackbar.dataset.text);
}

function mdcInitSnackbar(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  [].map.call(scope.querySelectorAll('.mdc-snackbar'), function (el) {
    if (!el || el.mdcSnackbar) return;
    el.mdcSnackbar = new MDCSnackbar(el);

    // Adjust snackbar offset when displaying (opening) snackbar
    el.mdcSnackbar.listen('MDCSnackbar:opened', () => {
      let $fab = $('.cb-fab.cb-fab--index'),
        bottomOffset = 0,
        maxSnackSize = window.innerWidth,
        snackSize = parseInt($(el).find('.mdc-snackbar__surface').outerWidth() || 0);

      if ($fab.length) {
        maxSnackSize = parseInt($fab.css('left') || 0) - $fab.outerWidth();
        bottomOffset = bottomOffset + parseInt($fab.css('bottom') || 0) + $fab.outerHeight();
      }

      if (window.innerWidth >= 600 && $fab.length && snackSize > maxSnackSize) {
        $(el).css({
          'bottom': bottomOffset + 'px',
          'width': '100%'
        });
      };
    });

    // Remove from DOM after closing
    el.mdcSnackbar.listen('MDCSnackbar:closed', () => {
      $(el).remove();
    });

    // Open snackbar
    if (el.classList.contains('cb-snackbar--open')) {
      el.mdcSnackbar.open();
      el.classList.remove('cb-snackbar--open');
    }
  });
}



// << END of MDC inits;





/*
    Context bar
*/

/* Contextual bar toggle */
// For global search button etc.
$(document).on('click', '[data-toggle="contextual-bar"]', function () {
  var contextualBarName = $(this).attr('data-target');

  if (contextualBarName) {
    var $bar = findContextualBar(contextualBarName);
    toggleContextBar($bar, true)
  }
});


function findContextualBar(name) {
  return $('.cb-contextual-bar[data-content="' + name + '"]');
}


function toggleContextBar($contextBar, toggle) {
  if (!$contextBar.length) {
    console.info('No context bar found');
    return;
  }

  var fixed = $contextBar.hasClass('cb-contextual-bar--top-bar');

  // Only one 'fixed' context bar;
  // Close all active context bars (eg. global search);
  if (fixed) {
    var selector = '.cb-contextual-bar--top-bar.cb-contextual-bar--active';
    $(selector).removeClass('cb-contextual-bar--active');
  }

  // Open/Activate context bar
  $contextBar.toggleClass('cb-contextual-bar--active', toggle);

  // Need to be after context open/close (to get curretn active state of context bar)
  if ($contextBar.data('content') == 'search') {
    // Toggle document body & fab on global search context bar open/close
    document.body.bodyScrollStateChange(toggle);

    // Open menu scrim
    $('.cb-menu-scrim').toggleClass('cb-menu-scrim--open', toggle);
  }

  // Add context active state to menu bar
  if (fixed) {
    $('header.mdc-top-app-bar').toggleClass('cb-mdc-top-app-bar--context-active', toggle);
  } else {
    var listToolbar = $contextBar.closest('.cb-list-table-toolbar');
    if (listToolbar.length) listToolbar.toggleClass('cb-list-table-toolbar--context-active', toggle);
  }

  // Focus input if present;
  if (toggle) $contextBar.find('input').focus();


  // Move expansion panel group titles
  var $listContent = $contextBar.closest('.cb-list-table-toolbar').siblings('.cb-list-content');
  if ($listContent.length) {
    var $expansionPanelWrapper = $listContent.find('.cb-expansion-panel-group-wrapper');

    $expansionPanelWrapper.find('.cb-expansion-panel-group-title-wrapper').each(function () {
      moveExpansionPanelGroupTitle(this);
    });
  }
}

// part of #expansion_panel.es6 'move' method
function moveExpansionPanelGroupTitle(obj) {
  let $obj = $(obj),
    objY = 0;

  // Current pannel offset Y
  let matrix = $obj.css('-webkit-transform') ||
    $obj.css('-moz-transform') ||
    $obj.css('-ms-transform') ||
    $obj.css('-o-transform') ||
    $obj.css('transform');

  if (matrix !== 'none') {
    // matrix( scaleX(), skewY(), skewX(), scaleY(), translateX(), translateY() );
    let matrixValues = matrix.split('(')[1].split(')')[0].split(',');
    objY += +matrixValues[5];
  }
  if (objY < 0) objY = 0;

  // Panel header sticky position
  let defaultTop = parseFloat($obj.css('--sticky-header-offset') || 0) - 2;
  $obj.css('top', defaultTop - objY + 'px');
}


// Set collection context bar with selected count.
function setCollectionContextBar($wrapper, inputSelector) {
  var name = $wrapper.attr('data-target'),
    checkedItemsCount = $wrapper.find(inputSelector + ':checked').length,
    open = checkedItemsCount > 0,
    $bar = findContextualBar(name);

  // Set selected item/s count
  if (open) {
    $bar.find('.mdc-top-app-bar__title').html(I18n.t('selected', { count: checkedItemsCount }))
  }

  toggleContextBar($bar, open);
}


// Expansion panel group select (all)
function groupSelectAllState($group) {
  var $checkboxes = $group.find('.cb-expansion-panel .cb-panel-select'),
    $groupTitle = groupTitleOfGroup($group),
    $groupSelectAll = $groupTitle.find('.cb-panel-select-all');

  /* State  Checked  Indeterminate
     mixed  false    true
     none   false    false
     all    true     false
  */

  var notChecked = $checkboxes.not(':checked').length > 0,
    // boolean (is at least one checked)
    isChecked = $checkboxes.is(':checked'),
    mixed = notChecked && isChecked;

  $groupSelectAll.prop('checked', !mixed && isChecked);
  $groupSelectAll.prop('indeterminate', mixed);
}


function groupSelectToggleAll($wrapper, toggle, allPanels) {
  if (!$wrapper) return;

  if (allPanels) {
    $wrapper.find('.cb-panel-select-all').prop({ 'indeterminate': false, 'checked': toggle });
  } else {
    var $groupTitle = groupTitleOfGroup($wrapper);
    $groupTitle.find('.cb-panel-select-all').prop({ 'indeterminate': false, 'checked': toggle });
  }

  $wrapper.find('.cb-panel-select, .cb-panel-select-all').prop('checked', toggle);
  $wrapper.find('.cb-expansion-panel__header').toggleClass('cb-expansion-panel__header--selected', toggle);
}

function groupTitleOfGroup($group) {
  var group = $group.attr('data-group'),
    $groupTitle = $group.parent().find('.cb-expansion-panel-group-title-wrapper[data-group=' + group + ']');

  return $groupTitle;
}


// Expansion panel group select
$(document).on('change', '.cb-expansion-panel .cb-panel-select', function () {
  var $checkbox = $(this),
    $panelHeader = $checkbox.closest('.cb-expansion-panel__header'),
    $group = $checkbox.closest('.cb-expansion-panel-group');

  $panelHeader.toggleClass('cb-expansion-panel__header--selected', $checkbox.is(':checked'));

  groupSelectAllState($group);
  setCollectionContextBar($group.closest('.cb-expansion-panel-group-wrapper'), '.cb-panel-select');
});


// Expansion panel group select all;
$(document).on('change', '.cb-expansion-panel-group-wrapper .cb-panel-select-all', function () {
  var group = $(this).closest('.cb-expansion-panel-group-title-wrapper').attr('data-group'),
    $group = $('.cb-expansion-panel-group[data-group=' + group + ']'),
    val = $(this).is(':checked');

  groupSelectToggleAll($group, val);
  setCollectionContextBar($group.closest('.cb-expansion-panel-group-wrapper'), '.cb-panel-select');
});


// Toggle context bar if any item selected
$(document).on('change', '.cb-collection-select, .cb-collection-select-all', function () {
  var $table = $(this).closest('table');
  setCollectionContextBar($table, '.cb-collection-select');
});


// Close context bar
$(document).on('click', '.cb-contextual-bar .cb-contextual-bar__close', function () {
  var $contextBar = $(this).closest('.cb-contextual-bar');

  if ($contextBar.attr('data-content').endsWith('_collection')) {
    var contextName = $(this).closest('.cb-contextual-bar').attr('data-content'),
      $wrapper = $('[data-target="' + contextName + '"]');

    // TODO: Make data-parent attribute
    var mdcTable = $wrapper.closest('.mdc-data-table')[0],
      $expansionPanel = $wrapper.closest('.cb-expansion-panel-group-wrapper');

    if (mdcTable) {
      // NOTE: Use MDC setSelectedRowIds function to deselect all rows (set to [])
      // mdcDataTable.setSelectedRowIds([]);
      mdcTable = new MDCDataTable(mdcTable);
      mdcTable.setSelectedRowIds([]);
    } else if ($expansionPanel) {
      groupSelectToggleAll($expansionPanel, false, true);
    }
  }

  toggleContextBar($contextBar, false);
});

// << END of Context bar





// Collection action (add ids of selected items)
$(document).on('click', '.cb-contextual-bar #collectionActions a', function (e) {
  e.stopPropagation();
  e.preventDefault();
  var $action = $(this),
    _href = $action.attr('href'),
    paramName = _href.lastIndexOf('?') > -1 ? '&ids=' : '?ids=',
    contextName = $action.closest('.cb-contextual-bar').attr('data-content'),
    $wrapper = $('[data-target="' + contextName + '"]'),
    target = $action.data('target');

  if ($action.hasClass('js-post-to-form')) {
    var inputs = $wrapper[0].querySelectorAll('.cb-collection-select:checked, .cb-panel-select:checked');
    postToCAForm(_href, inputs);
    return false;
  }
  var ids = $.map($wrapper.find('.cb-collection-select:checked, .cb-panel-select:checked'), function (val, i) {
    return $(val).data('object-id');
  });

  if (target) {
    var url = new URL(this.href);
    url.searchParams.set('ids', ids);
    this.href = url.toString();
  } else if ($action.data('remote-collection-action')) {
    var url = new URL(this.href);
    url.searchParams.set('ids', ids);
    $.get(url, function() {
      // deselect items;
      var $closeBtn = $action.closest('.cb-contextual-bar').find('.cb-contextual-bar__close');
      if ($closeBtn) $closeBtn.trigger('click');
    });
  } else {
    window.location.href = _href + paramName + ids;
  }
})

function postToCAForm(path, inputs) {
  const form = document.createElement('form');
  const params = {};
  const csrfInput = document.createElement('input');
  form.method = 'post';
  form.action = path;

  csrfInput.type = 'hidden'
  csrfInput.name = 'authenticity_token'
  csrfInput.value = document.head.querySelector('meta[name="csrf-token"]').content;
  form.appendChild(csrfInput);

  inputs.forEach((item, i) => {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'ids[]';
    hiddenField.value = item.dataset.objectId;
    form.appendChild(hiddenField);
  });

  document.body.appendChild(form);
  form.submit();
}


/*
    Global
*/

$(document).on('click', '#changePassword', function () {
  var isActive = this.checked,
    $wrappers = $('.password:not(input)'),
    $inputs = $wrappers.find('.mdc-text-field');

  $wrappers.toggle(isActive);
  $inputs.each(function () {
    this.mdcTextField.disabled = !isActive;
  });
});

function submitForm($form) {
  if ($form.length) {
    if ($form.data('remote')) {
      Rails.fire($form[0], 'submit');
    } else {
      $form.submit();
    }
  }
}

$(document).on('change', '.show_for form.edit_risk_item [name^="risk_item"]', function () {
  var $form = $(this).closest('form');
  submitForm($form);
});

$(document).on('change', '.show_for form[id^="inline_edit_"][id$="_file_attachment"] input.file', function (e) {
  var $form = $(this).closest('form'),
    timeoutId,
    $showWrapper = $(this).closest('.show-for-wrapper');

  $showWrapper.addClass('show-for-wrapper--disabled');
  $showWrapper.find('.cb-inline-form-progress').removeClass('mdc-linear-progress--closed')

  if (timeoutId) clearTimeout(timeoutId);
  timeoutId = setTimeout(function () {
    submitForm($form);
  }, 100);
});

$(document).on('change', 'ul.todo-list form[id^="inline_edit_"][id$="_file_attachment"] input.file', function (e) {
  var $form = $(this).closest('form'),
    timeoutId,
    $parent = $(this).closest('.todo-list-item');

  $parent.find('.cb-inline-form-progress').removeClass('mdc-linear-progress--closed');
  $parent.find('.inline-edit-file-attachment-info').show();

  if (timeoutId) clearTimeout(timeoutId);
  timeoutId = setTimeout(function () {
    submitForm($form);
  }, 100);
});

// << END of Global

/*
  View Switcher
*/
function initViewSwitcher(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  [].map.call(scope.querySelectorAll('.view-switch'), function (el) {
    let $el = $(el),
      $activeItem = $el.find('.active');

    if ($activeItem.length) {
      $el.append($('<span class=view-switch__indicator></span>'));
      el.indicator = $el.find('.view-switch__indicator');

      let offset = $activeItem[0].offsetLeft || 0;
      el.indicator.css({ 'transform': `translateX(${offset}px)` });
    }
  });
}


/*
  Checklists
*/
function initChecklists(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  [].map.call(scope.querySelectorAll('.cb-checklist'), function (el) {
    el.loadItems = function () {
      let $checklist = $(this),
        // $progress = $checklist.find('.mdc-linear-progress'),
        $progress,
        ajaxLoadStart;

      $.ajax({
        url: $checklist.attr('data-url'),
        beforeSend: function () {
          ajaxLoadStart = new Date().getTime();
          if ($progress && $progress[0].mdcLinearProgress) {
            $progress[0].mdcLinearProgress.foundation.open();
          }
        },
        success: function (data) {
          let $checklistHtml = $($(data).html());
          $checklist.html($checklistHtml);
          app.initialize($checklistHtml);
          this.itemsLoaded = true;
        },
        error: function (xhr) {
          let $content = $checklist.find('.cb-skeleton-loading');
          if ($content.length) {
            let $newHtml = $('<div class="cb-skeleton-loading cb-skeleton-loading--list cb-skeleton-loading--error">'),
              errorHtml = '<div class="cb-skeleton-loading__content">' +
                '<i class="material-icons">error</i>' +
                '<span>' + I18n.t('views.tab.error') + '</span></div>',
              $errorHtml = $(errorHtml).css({ 'height': $content.innerHeight() });

            $newHtml.html($errorHtml);
            $content.replaceWith($newHtml);
          }
        },
        complete: function () {
          if ($progress && $progress[0].mdcLinearProgress) {
            $progress[0].mdcLinearProgress.foundation.close();
          }
          console.info('checklist', (new Date().getTime() - ajaxLoadStart) / 1000 + 's',)
        }
      });
    }

    el.loadItems();
  });
};
// << END of Checklists







/*
  Leaflet maps
*/

/* Full map */
$(document).on('click', '.cb-map-fullscreen-toggle', function () {
  var $target = $(this).closest($(this).data('target')),
    icon = 'fullscreen',
    isFullscreen = $(this).text() == 'fullscreen',
    transitionDuration = $(this).data('duration') || 200,
    cssOpts = { top: '', left: '', width: '', transitionDuration: transitionDuration + 'ms' };

  if (isFullscreen) {
    $('body').css('overflow', 'hidden')
    icon = 'fullscreen_exit';
    // Set initial x,y position
    var offsets = $target.offset(),
      topBarOffset = 0;

    if (window.innerWidth >= 600) topBarOffset = 64;

    Object.assign(cssOpts, {
      top: -offsets['top'] + window.scrollY + topBarOffset,
      left: -offsets['left']
    });

    setTimeout(function () {
      $target.addClass('map-area--fixed');
      $target.css({ transitionDuration: '0ms' });
    }, transitionDuration);
  } else {
    $('body').css('overflow', '');
  }

  // Reset fixed class;
  $target.removeClass('map-area--fixed');

  // Animate fullscreen - need to call after removing --fixed state;
  setTimeout(function () {
    $target.css(cssOpts);
    $target.find('#main_map').css({ transitionDuration: transitionDuration + 'ms' });
    $target.toggleClass('map-area--fullscreen', isFullscreen);
  }, 10);

  // Change icon;
  $(this).text(icon);
});


function initHomeMap() {
  if (!$('#home-map').length) return;
  const provider = new OpenStreetMapProvider();
  const L = window['L'];
  var container = L.DomUtil.get('main_map');
  if (container != null) {
    container._leaflet_id = null;
  }
  const current_loc = $('#home-map').data('current_loc');

  const map = L.map('main_map', { gestureHandling: true }).setView(current_loc, 13);
  const icon = L.icon({
    iconSize: [32, 41],
    popupAnchor: [0, -20],
    iconUrl: '/images/leaflet/marker-icon.svg'
  });

  L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    minZoom: 2,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);

  const markers = L.markerClusterGroup().addTo(map);

  const searchControl = new GeoSearchControl({
    provider: provider,
    style: 'bar',
    showMarker: false,
    maxMarkers: 1,
    retainZoomLevel: false,
    animateZoom: true,
    autoClose: true,
    openPopup: false,
    searchLabel: I18n.t('js.leaflet.search.placeholder'),
    showPopup: false,
    autoCompleteDelay: 250,
    keepResult: true
  });

  map.addControl(searchControl);

  var customOptions =
  {
    'maxWidth': '500',
    'minWidth': '200',
    'className': 'locatable-wrapper'
  }
  var contentUrl = $('#home-map').data('content-url');
  var processData = function(data) {
    var coordinates = data;
    if (typeof(coordinates) === "string") coordinates = JSON.parse(coordinates);
    $.each(coordinates, function (index, value, d) {
      var traffic_icon = L.icon({
        iconSize: [32, 41],
        popupAnchor: [0, -20],
        iconUrl: '/images/leaflet/' + value['traffic_light'] + '.svg'
      });
      var infos = "<div class='map_popup' id=" + value['id'] + "></div>"
      var newMarker = new L.marker([value['x'], value['y']], { title: value['title'], icon: traffic_icon, address_id: value['id'], target_data: 'locatable' }).bindPopup(infos, customOptions);
      newMarker.on('click', markerOnClick);
      markers.addLayer(newMarker);
      map.fitBounds(markers.getBounds(markers));
    })
  }

  if (contentUrl) {
    $.ajax({
      url: contentUrl,
      dataType: 'JSON',
      success: function (data, status) { processData(data.addresses) }
    })
  } else {
    var data = $('#home-map').data('coords');
    processData(data);
  }
}


/* List map */
function initListMap() {
  if (!$('.list-map #list-map').length) return;

  const L = window['L'];
  var container = L.DomUtil.get('list-map');
  if (container != null) {
    container._leaflet_id = null;
  }

  const provider = new OpenStreetMapProvider();
  const current_loc = $('.list-map').data('current_loc');

  const map = L.map('list-map', { gestureHandling: true }).setView(current_loc, 13);
  const icon = L.icon({
    iconSize: [32, 41],
    popupAnchor: [0, -20],
    iconUrl: '/images/leaflet/marker-icon.svg'
  });

  var coordinates = $('.list-map').data('coords');

  L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
    maxZoom: 18,
    minZoom: 2,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);

  const markers = L.markerClusterGroup().addTo(map);

  var customOptions =
  {
    'maxWidth': '500',
    'minWidth': '200',
    'className': 'address-wrapper'
  }

  $.each(coordinates, function (index, value, d) {
    var infos = "<div class='map_popup' id=" + value['id'] + "></div>"
    var newMarker = new L.marker([value['x'], value['y']], { title: value['title'], icon: icon, address_id: value['id'], target_data: 'address' }).bindPopup(infos, customOptions);
    newMarker.on('click', markerOnClick);
    markers.addLayer(newMarker);
  });

  map.fitBounds(markers.getBounds(markers));
}

window.initDisplayMap = initDisplayMap;
function initDisplayMap() {
  if (!$('#display-map').length) return;

  const L = window['L'];
  var container = L.DomUtil.get('display-map');
  if (container != null) {
    container._leaflet_id = null;
  }

  const provider = new OpenStreetMapProvider();
  const current_loc = $('.display-map').data('current_loc');

  const map = L.map('display-map', { gestureHandling: true }).setView(current_loc, 13);
  const icon = L.icon({
    iconSize: [32, 41],
    popupAnchor: [0, -20],
    iconUrl: '/images/leaflet/marker-icon.svg'
  });

  var coordinates = $('.display-map').data('coords');

  L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
    maxZoom: 18,
    minZoom: 2,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);

  const markers = L.markerClusterGroup().addTo(map);
  var customOptions =
  {
    'maxWidth': '500',
    'minWidth': '200',
    'className': 'address-wrapper'
  }

  $.each(coordinates, function (index, value, d) {
    var infos = "<div class='map_popup' id=" + value['id'] + "></div>"
    var newMarker = new L.marker([value['x'], value['y']], { title: value['title'], icon: icon, address_id: value['id'], target_data: 'address' }).bindPopup(infos, customOptions);
    newMarker.on('click', markerOnClick);
    markers.addLayer(newMarker);
  });

  map.fitBounds(markers.getBounds(markers));
}

window.initFormMap = initFormMap;
function initFormMap() {
  if (!$('#new-address-map').length) return;
  const L = window['L'];
  var container = L.DomUtil.get('new-address-map');
  if (container != null) {
    container._leaflet_id = null;
  }

  const provider = new OpenStreetMapProvider();
  const current_loc = $('#address-form-map-wrapper').data('current_loc');
  const map = L.map('new-address-map').setView(current_loc, 13);
  const icon = L.icon({
    iconSize: [32, 41],
    popupAnchor: [0, -20],
    id: 'formMarkerIcon',
    iconUrl: '/images/leaflet/marker-icon.svg'
  });

  L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
    maxZoom: 18,
    minZoom: 2,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);

  const marker = L.marker(current_loc, { draggable: true, icon: icon, id: 'formMarker' }).addTo(map);

  map.on('click', function (ev) {
    var position = ev.latlng;
    map.eachLayer(function (layer) {
      if (layer.options.id === 'formMarker') {
        var marker = layer;
      }
    });
    updateMarkerData(map, marker, position);
  });

  const searchControl = new GeoSearchControl({
    provider: provider,
    style: 'bar',
    showMarker: true,
    maxMarkers: 1,
    retainZoomLevel: false,
    animateZoom: true,
    autoClose: true,
    openPopup: false,
    searchLabel: I18n.t('js.leaflet.search.placeholder'),
    showPopup: false,
    autoCompleteDelay: 250,
    keepResult: true,
    marker: {
      icon: icon,
      id: 'searchMarker',
      draggable: true
    }
  });

  map.addControl(searchControl);

  map.on('geosearch/showlocation', function (e) {
    updateLatLangValue(e.location.y, e.location.x);
    removeMarker('formMarker', map);
  });

  map.on('geosearch/marker/dragend', function (e) {
    updateLatLangValue(e.location.lat, e.location.lng)
    map.panTo(new L.LatLng(e.location.lat, e.location.lng));
  });

  marker.on('dragend', function (event) {
    var marker = event.target;
    var position = marker.getLatLng();
    updateMarkerData(map, marker, position);
  });


  $("#address_street, #address_city").keyup(function (event) {
    delay(function (e) {
      var city = $("#address_city").val();
      var address = $("#address_street").val();
      var full_address = city.concat(' ', address);

      $.get(location.protocol + '//nominatim.openstreetmap.org/search?format=json&q=' + full_address, function (data) {
        if (data[0]) {
          var lat = data[0].lat;
          var lng = data[0].lon;

          marker.setLatLng(new L.LatLng(lat, lng), { draggable: 'true' });
          map.panTo(new L.LatLng(lat, lng)).setView([lat, lng], 16);
          updateLatLangValue(lat, lng);
        }
      });

    }, 500)
  });

}


function updateLatLangValue(lat, lng) {
  if (lat & lng) {
    $('#address_latitude').val(lat);
    $('#address_longitude').val(lng);
  }
}


function updateMarkerData(map, marker, position) {
  marker.setLatLng(new L.LatLng(position.lat, position.lng), { draggable: 'true' });
  map.panTo(new L.LatLng(position.lat, position.lng));
  updateLatLangValue(position.lat, position.lng);
}


function addMarker(e, map) {
  var icon = L.icon({
    iconSize: [32, 41],
    popupAnchor: [0, -20],
    iconUrl: '/images/leaflet/marker-icon.svg'
  });
  var newMarker = new L.marker(e.latlng, { icon: icon, draggable: true }).addTo(map);
}


function removeMarker(id, map) {
  map.eachLayer(function (layer) {
    if (layer.options.id === id) {
      map.removeLayer(layer)
    }
  });
}


function markerOnClick(e) {
  var id = e.target.options.address_id;
  var target_data = (e.target.options.target_data.length ? e.target.options.target_data : 'address');
  if (id) {
    $.ajax({
      url: '/addresses/map_popup.js',
      type: 'GET',
      data: { id: id, target_data: target_data },
      format: 'js',
      cache: false,
      success: function (data, status) {

      }
    });
  }
}

function initToolbarMap() {
  const L = window['L'];
  var container = L.DomUtil.get('toolbar_address_map');
  if (!$(container).length) return;

  const current_loc = $(container).data("coordinates");
  if (!current_loc) return;

  if (container != null) {
    container._leaflet_id = null;
  }

  const map = L.map('toolbar_address_map', {
    attributionControl: false
  }).setView(current_loc, 13);
  const icon = L.icon({
    iconSize: [32, 41],
    popupAnchor: [0, -20],
    id: 'formMarkerIcon',
    iconUrl: '/images/leaflet/marker-icon.svg'
  });

  L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    minZoom: 2,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }).addTo(map);

  L.marker(current_loc, { icon: icon, id: 'formMarker' }).addTo(map);
  L.control.attribution({ position: 'topright' }).addTo(map);
}


// << END of Leaflet maps


/*
  CHARTS
*/
function initCharts(scope) {
  if (!scope) scope = document;
  scope = scope[0] || scope;

  initBarCharts(scope);
  initDoughnutCharts(scope);
  initLineCharts(scope);
}

function _colorForCharts(n) {
  const chartColors = [
    '#00A5E7', '#FF5B84', '#FF9A4F', '#00C2BF', '#9A6AF8',
    '#00C32D', '#FFCA66', '#AF169A', '#003FD5', '#12D7C8'
  ]
  return chartColors[n];
}

function initLineCharts(scope) {
  // Defaults
  Chart.overrides.line.spanGaps = true;
  Chart.overrides.line.pointBorderWidth = 6;
  Chart.overrides.line.pointHoverRadius = 9;
  Chart.overrides.line.pointHitRadius = 64;
  Chart.overrides.line.tension = 0.2;

  const skipped = (ctx, value, def) => ctx.p0.skip || ctx.p1.skip ? value : def;

  [].map.call(scope.querySelectorAll('.line-chart'), function (chart) {
    let $chart = $(chart),
      labels = $chart.attr('data-labels'),
      dataset = $chart.attr('data-dataset'),
      chartLabels = $chart.attr('data-show-labels') == 'true';

    // Dataset options
    dataset = JSON.parse(dataset);
    dataset.forEach((line, i) => {
      // Get color name if provided
      let colorName = line.backgroundColor,
          color;

      if (line.label == null) {
        color = "#CCCCCC";
        line.label = $chart.closest('.charts').data('no-title');
      } else if (!colorName) {
        color = _colorForCharts(i % 10);
      } else {
        let clrDiv = $('<i class="bg-' + colorName + '"></i>');
        $('body').append(clrDiv);
        color = clrDiv.css('backgroundColor') || colorName;
        clrDiv.remove();
      }

      line.backgroundColor = color;
      line.borderColor = color;

      line.segment = {
        borderDash: ctx => skipped(ctx, [10, 8]),
        borderWidth: ctx => skipped(ctx, 2, 4),
      };
    });

    new Chart(chart, {
      plugins: chartLabels ? [ChartDataLabels] : [],
      type: 'line',
      data: {
        labels: JSON.parse(labels),
        datasets: dataset
      },
      options: _optionsForLineChart(chartLabels)
    });
  });
}

function initBarCharts(scope) {
  // Defaults
  Chart.overrides.bar.maxBarThickness = 48;

  [].map.call(scope.querySelectorAll('.bar-chart'), function (chart) {
    let $chart = $(chart),
      labels = $chart.attr('data-labels'),
      dataset = $chart.attr('data-dataset'),
      chartStacked = $chart.attr('data-stacked') == 'true',
      chartHorizontal = $chart.attr('data-horizontal') == 'true',
      chartLabels = $chart.attr('data-show-labels') == 'true',
      chartMax;

    // Dataset options
    dataset = JSON.parse(dataset);
    dataset.forEach((bar, i) => {
      // Get color name if provided
      let colorName = bar.backgroundColor,
        color;

      if (bar.label == null) {
        color = "#CCCCCC";
        bar.label = $chart.closest('.charts').data('no-title');
      } else if (!colorName) {
        color = _colorForCharts(i % 10);
      } else {
        let clrDiv = $('<i class="bg-' + colorName + '"></i>');
        $('body').append(clrDiv);
        color = clrDiv.css('backgroundColor') || colorName;
        clrDiv.remove();
      }

      bar.backgroundColor = color;
      bar.borderColor = color;
    });

    new Chart(chart, {
      plugins: chartLabels ? [ChartDataLabels] : [],
      type: 'bar',
      data: {
        labels: JSON.parse(labels),
        datasets: dataset
      },
      options: _optionsForBarChart(chartStacked, chartHorizontal, chartMax, chartLabels)
    });
  });
}

function initDoughnutCharts(scope) {
  [].map.call(scope.querySelectorAll('.doughnut-chart'), function (chart) {
    let $chart = $(chart),
      labels = $chart.attr('data-labels'),
      dataset = $chart.attr('data-dataset'),
      chartLabels = $chart.attr('data-show-labels') == 'true';

    new Chart(chart, {
      plugins: chartLabels ? [ChartDataLabels] : [],
      type: 'doughnut',
      data: {
        labels: JSON.parse(labels),
        datasets: JSON.parse(dataset)
      },
      options: _optionsForDoughnutChart(chartLabels)
    });
  });
}

function _optionsForBarChart(stacked = false, horizontal = false, max = false, labels = false) {
  let categoryAxisOptions = {
    type: 'category',
    stacked: stacked,
    min: 0,
    ticks: {
      display: true,
      autoSkip: false
    },
    labels: (el) => {
      const newLabels = [],
            context = el.scale,
            data = context.chart.data,
            legendItems = context.chart.legend.legendItems;

      if (stacked) { return data.labels };

      function totalSum(total, dataPoint) { return total + dataPoint };

      data.labels.forEach((label, idx) => {
        let datasetArr = [];

        data.datasets.forEach((dataset, dataIdx) => {
          if (legendItems && legendItems[dataIdx].hidden) return;

          let val = dataset.data[idx];
          if (val != undefined) {
            datasetArr.push(val)
          } else {
            datasetArr.push(0);
          };
        })

        let sum = datasetArr.reduce(totalSum, 0),
            avg = sum > 0 ? (sum / (datasetArr.length)).toFixed(1) : 0;

        label = [label, '('+ I18n.t('charts.avg') +': '+ avg +')'];

        newLabels.push(label);
      })

      return newLabels;
    }
  };

  let valueAxisOptions = {
    bounds: 'ticks',
    stacked: stacked,
    min: 0,
    ticks: {
      precision: 0
    },
    grace: 5
  };
  if (max) valueAxisOptions.max = max;


  let opts = {
    scaleShowValues: true,
    indexAxis: horizontal ? 'y' : 'x',
    scales: {
      x: horizontal ? valueAxisOptions : categoryAxisOptions,
      y: horizontal ? categoryAxisOptions : valueAxisOptions
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: { size: 14 }
        },
        onClick: function (e, legendItem, legend) {
          Chart.defaults.plugins.legend.onClick(e, legendItem, legend);
          if (!stacked) { legend.chart.update() };
        },
        onHover: function (e) { e.native.target.style.cursor = 'pointer'; },
        onLeave: function (e) { e.native.target.style.cursor = 'default'; }
      }
    }
  }

  if (labels) {
    // All data labels options
    // https://chartjs-plugin-datalabels.netlify.app/guide/options.html#scriptable-options

    opts.plugins.datalabels = {
      anchor: 'end',
      align: horizontal ? 'right' : 'top',
      clamp: true,
      display: 'auto',
      formatter: (value, context) => {
        // Sum elements only if stacked bars
        // defaults to return value;
        if (!stacked) return;

        const datasetArr = [];
        let lastVisibleItemIndex;

        context.chart.data.datasets.forEach((dataset, index) => {
          // Legend hidden (filtered) so don't count this item;
          if (context.chart.legend.legendItems[index].hidden) return;

          let val = dataset.data[context.dataIndex];

          // Add value to total if values present;
          if (val != undefined) {
            datasetArr.push(val)

            // Set this record as the last visible record
            // for which the total sum label will be rendered.
            lastVisibleItemIndex = index;
          };
        })

        // Sum all values in array;
        function totalSum(total, dataPoint) { return total + dataPoint };
        let sum = datasetArr.reduce(totalSum, 0);

        // Render the sum of the grouped values ​​on the last element,\
        // otherwise render nothing. It is important not to render an
        // empty string "" because label display: 'auto' will hide it
        // (one element above another)
        return context.datasetIndex == lastVisibleItemIndex ? sum : null;

      },
      font: {
        weight: 'bold',
        size: 13
      }
    }
  }

  return opts;
}

function _optionsForDoughnutChart(labels = false) {
  let opts = {
    scaleShowValues: true,
    circumference: 360,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: { size: 16 }
        },
        onHover: function (e) { e.native.target.style.cursor = 'pointer'; },
        onLeave: function (e) { e.native.target.style.cursor = 'default'; }
      }
    }
  }

  if (labels) {
    // All data labels options
    opts.plugins.datalabels = {
      anchor: 'center',
      align: 'center',
      clamp: true,
      display: 'auto',
      color: '#FFF',
      padding: 5,
      font: {
        weight: 'bold',
        size: 18,
      }
    }
  }

  return opts;
}

function _optionsForLineChart(labels = false) {
  let opts = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        ticks: { precision: 0 },
        grace: 4
      },
      x: {
        labels: (el) => {
          const newLabels = [],
            context = el.scale,
            data = context.chart.data,
            legendItems = context.chart.legend.legendItems;
          let total_sum = 0;

          function totalSum(total, dataPoint) { return total + dataPoint };

          data.labels.forEach((label, idx) => {
            let datasetArr = [];

            data.datasets.forEach((dataset, dataIdx) => {
              if (legendItems && legendItems[dataIdx].hidden) return;

              let val = dataset.data[idx];
              if (val != undefined) datasetArr.push(val);
            })

            let sum = datasetArr.reduce(totalSum, 0);
            total_sum += sum;
            label = [(label + ' (' + sum + ')'), total_sum];

            newLabels.push(label);
          })

          return newLabels;
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          font: { size: 14 }
        },
        onClick: function (e, legendItem, legend) {
          Chart.defaults.plugins.legend.onClick(e, legendItem, legend);
          legend.chart.update();
        },
        onHover: function (e) { e.native.target.style.cursor = 'pointer'; },
        onLeave: function (e) { e.native.target.style.cursor = 'default'; }
      }
    }
  };

  if (labels) {
    // All data labels options
    opts.plugins.datalabels = {
      anchor: 'top',
      align: 'top',
      clamp: true,
      display: 'auto',
      color: '#222',
      padding: 5,
      font: {
        size: 14
      }
    }
  }

  return opts;
}

// << END of CHARTS


/*
  Lightbox
*/

function lightboxOpts() {
  lightbox.enable();
  lightbox.build();

  lightbox.option({
    'albumLabel': I18n.t('lightbox.album_label'),
    'fadeDuration': 400,
    'imageFadeDuration': 200,
    'resizeDuration': 400,
    'wrapAround': true
  });
};



/*
    Mega menu (touch devices)
*/
$(document).on('touchstart', '.navigation-wrapper', function (e) {
  let $navWrapper = $(this);
  if ($navWrapper.hasClass('navigation-wrapper--fullsize')) return;

  let $navParent = $navWrapper.closest('.main-nav__item-wrapper'),
    swipe = e.originalEvent.touches,
    start = swipe[0].pageY;

  $navWrapper.css('height', $navWrapper.outerHeight());

  $(this).on('touchmove', function (e) {
    if (!$navParent.hasClass('open')) return;

    let contact = e.originalEvent.touches,
      end = contact[0].pageY,
      distance = end - start;

    if (distance < -60) {
      // Move up
      $navWrapper.css('height', $navWrapper[0].scrollHeight);
      $navWrapper.addClass('navigation-wrapper--fullsize');
      $(this).trigger('touchend');

    } else if (distance > 60) {
      // Move down
      if ($navParent) {
        // NOTE: Can't trigger('hidden.bs.dropdown')
        $('.cb-menu-scrim').removeClass('cb-menu-scrim--open');
        document.body.bodyScrollStateChange();

        $navParent.removeClass('open');
        $navParent.find('.dropdown-backdrop').remove();
      }
      $(this).trigger('touchend');
    }
  })
    .one('touchend', function () {
      // NOTE: Touch realeased
      $(this).off('touchmove touchend');
    });
});
